import React, { RefObject } from "react";

import {
  Box,
  Button,
  Typography,
  TextField,
  // Customizable Area Start
  FormControlLabel,
  FormControl,
  IconButton,
  Select,
  Switch,
  SwitchProps,
  Popover,
  Snackbar,
  DialogTitle,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import Datepicker from "react-datepicker";
import { styled } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "react-datepicker/dist/react-datepicker.css";
import Divider from '@material-ui/core/Divider';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MenuItem from '@mui/material/MenuItem';
import 'react-phone-input-2/lib/style.css';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Footer from "../../navigationmenu/src/Footer.web"
import CloseIcon from '@material-ui/icons/Close';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Webcam from "react-webcam";
import CircleSharpIcon from '@mui/icons-material/CircleSharp';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { ErrorMessage, Formik } from "formik";
export const configJSON = require("./config");
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import CustomisableEditProfileController, {
  Props,
} from "./CustomisableEditProfileController.web";
import { Alert, AlertColor } from "@mui/material";
import { BackArrowBlack, avatar, imgUpload, leave2, leave1, fail, check, cancel } from "./assets";
import EditIcon from '@mui/icons-material/Edit';
// Customizable Area End

interface CameraCaptureProps {
  handleCaptureDisable: () => void;
  avatar: string;
  capture: () => void;
  captureProfile: () => void;
  userImg?: string;
  fullscreenRef: RefObject<HTMLDivElement>;
  webcamRef: RefObject<Webcam>;
}

interface ErrorAlertProps {
  message: string;
  onClose: () => void;
}

interface CustomSnackbarProps {
  open: boolean;
  onClose: () => void;
  message: string;
  type: AlertColor;
  showSuccess: boolean;
}

// Customizable Area Start
export const CustomizedButton = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 54,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(25px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#8CA74B',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: "gray"
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, onClose, message, type, showSuccess }) => (
  <Snackbar style={{ borderRadius: '8px' }} open={open} autoHideDuration={4000} onClose={onClose}>
    <Alert icon={<img style={{ width: '20px' }} src={showSuccess ? check : cancel} />} severity={type} style={{ backgroundColor: '#F8FFE7', color: '#1C1917', fontFamily: 'Poppins', fontWeight: 400, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
      {message}
      {!showSuccess && (
        <Button onClick={onClose} style={{ backgroundColor: '#557C29', borderRadius: '8px', color: '#FFFFFF', fontFamily: 'Poppins', fontWeight: 700, fontSize: '14px', textTransform: 'none', marginLeft: '5px' }} >
          Try again
        </Button>
      )}
    </Alert>
  </Snackbar>
);

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message, onClose }) => (
  <Alert className="alertPhotoContainer" severity="error" icon={false} style={{ display: 'block', marginTop: '32px' }}>
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>{message}
      <IconButton style={{ marginLeft: 'auto', color: '#DC2626' }} size="small" onClick={onClose}>  <CloseSharpIcon fontSize="inherit" /></IconButton>
    </div>
  </Alert>
);

// Customizable Area End

export default class CustomisableEditProfile extends CustomisableEditProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  cameraCapture =  (handleCaptureDisable: React.MouseEventHandler<HTMLButtonElement> | undefined, avatar: string | undefined, capture: React.MouseEventHandler<HTMLButtonElement> | undefined, captureProfile: React.MouseEventHandler<HTMLElement> | undefined, userImg: string | undefined, fullscreenRef: React.LegacyRef<HTMLDivElement> | undefined, webcamRef: React.LegacyRef<Webcam> | undefined ) => (
    <div style={{ margin: 0, padding: 0 }}>
      <Box className="headerDiv">
        <Box className="cameraBox">
          <Button className="backBtn" data-test-id="handleBackButton" onClick={handleCaptureDisable}>
            <img style={{ color: '#1C2110', height: '32px', width: '32px' }} src={BackArrowBlack} alt="Back" />
          </Button>
          <Typography className="cameraText">Camera</Typography>
        </Box>
        <Box className="profileDiv" style={{
          height: "50px",
          borderRadius: '50%'
        }}>
          {avatar && <><img src={avatar} style={{
            borderRadius: "50%",
            width: "50px",
            height: "100%"
          }} /> <IconButton aria-label="delete" size="small"> <ExpandMoreIcon fontSize="inherit" /> </IconButton></>}
        </Box>
      </Box>
      <div ref={fullscreenRef}>
        <Webcam
          style={{ height: "calc(100vh - 82px - 154px)", width: "100vw" }}
          ref={webcamRef}
          audio={false}
          screenshotFormat={"image/jpeg"}
        />
      </div>
      <Box className="bottomCameraDiv">
        <Box>
          <Typography className="photoTextTypo">PHOTO</Typography>
          <Button onClick={capture} style={{ borderRadius: '80px', border: '3px solid #8CA74B', height: '62px', width: '62px' }}>
            <CircleSharpIcon style={{ height: '60px', color: '#8CA74B', width: '60px' }} />
          </Button>
        </Box>
        {userImg && (
          <Box style={{ display: 'flex', position: 'absolute', right: 25 }} onClick={captureProfile} data-test-id="capturePhotoBox"> <img src={userImg} style={{ height: '100px', width: '100px' }} /></Box>
        )}
      </Box>
      <Popover
        className="dialogContainer"
        open={this.state.isCameraPopoverOpen}
        onClose={this.closeCameraPopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <PopoverProfileContent>
          <Box className="headerProfileBox">
            <Typography className="pictureText">Profile picture selection</Typography>
            <IconButton
              data-test-id='closeIcon'
              className="closeIcon"
              onClick={this.closeCameraPopover}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider style={{ marginTop: '10px', marginBottom: '25px', color: '#E7E5E4' }} />
          <Box
            data-test-id="mediaDragBox"
            className="imgInnerBox"
          >
            {this.state.userImg && (
              <img src={this.state.userImg} alt="Selected" style={{ width: '152px', height: '152px' }} />
            )}
          </Box>
          <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button className="cancelBtn" onClick={this.closeCameraPopover}>
              Cancel
            </Button>
            <Button className="saveBtn" data-test-id="saveProfileClickBtn" onClick={this.captureProfile}>
              Save as profile picture
            </Button>
          </Box>
        </PopoverProfileContent>
      </Popover>
    </div>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <UserProfileStyle>
          {this.state.isCaptureEnable ? (
            this.cameraCapture(this.handleCaptureDisable, this.state.userImg, this.capture, this.captureProfile,
              this.state.userImg, this.fullscreenRef, this.webcamRef)
          ) : (
            <>
              <HeaderDropdown data-test-id="headerComponent" name={this.state.fullName} email={this.state.email} key={this.state.keyIndex} profilePhoto={this.state.getProfile} navigation={this.props.navigation} id={""} />
              <Box>
                <Box className="navigationBox">
                  <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Button color="inherit" className="profileBreadcrumps" href="/"> Profile </Button>
                    <Typography color="textPrimary" className="editProfileText" >Edit Profile</Typography>
                  </Breadcrumbs>
                </Box>
                <Box className="editProfileBox">
                  <Button className="backButton" data-test-id="backButton">
                    <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                  </Button>
                  <Typography className="titleText">Edit profile</Typography>
                </Box>
              </Box>
              <Box className="mainDiv">
                <Box className="innerDiv">
                  {this.state.deviceSupportError && <ErrorAlert message={this.state.deviceSupportError} onClose={() => this.setState({ deviceSupportError: '' })} />}
                  {this.state.removePhotoError && <ErrorAlert message={this.state.removePhotoError} onClose={() => this.setState({ removePhotoError: '' })} />}
                  <Box className="topBox">
                    <img src={leave2} />
                    <img src={leave1} />
                  </Box>
                  <Box style={{ position: 'relative' }}>
                    <Box style={{ transform: "translate(45px, -80px)" }}>
                      {(!this.state.selectedImg && !this.state.getProfile) ? (
                        <Box className="profilePhotoName">
                          {this.getInitials()}
                        </Box>
                      ) : (
                        <Box className="avatarImgBox">
                          <img
                            className="profilePhoto"
                            src={this.state.selectedImg || this.state.getProfile}
                            alt="Profile"
                          />
                          {
                            this.state.getProfile && <Box className="editIconBox" data-test-id="handleEditPhotoBtn" onClick={this.handleEditPhoto}>
                            <EditIcon style={{ color: 'white',width: "18px", height: '18px' }}/>
                          </Box>
                          }
                        </Box>
                      )}
                    </Box>
                    {
                      !this.state.getProfile && <Button className="cameraIConBox" data-test-id="handleEditPhotoBtn" onClick={this.handleEditPhoto}>
                        <CameraAltOutlinedIcon style={{ color: 'white' }} />
                        <Typography className="editPicText">Edit picture</Typography>
                      </Button>
                    }
                  </Box>
                  <CustomSnackbar
                    data-test-id="snackBar"
                    open={this.state.showSuccessSnackbar}
                    onClose={() => this.setState({ showSuccessSnackbar: false })}
                    message={this.state.snackbarMessage}
                    type={this.state.snackbarType}
                    showSuccess={this.state.showSuccessSnackbar}
                  />
                  <Formik
                    data-test-id="formikProfileForm"
                    enableReinitialize
                    initialValues={{
                      fullName: this.state.fullName || '',
                      email: this.state.email || '',
                      birthDate: this.state.birthDate || null,
                      contactNumber: this.state.contactNumber || '',
                      address: this.state.address || '',
                      zipCode: this.state.zipCode || '',
                      city: this.state.city || '',
                      state: this.state.state || '',
                      newsToggleChecked: this.state.newsToggleChecked
                    }}
                    validationSchema={this.userProfileSchema}
                    onSubmit={values => {
                      this.submitProfileDetails(values);
                    }}
                  >
                    {({ handleSubmit, setFieldValue, handleReset }) => (
                      <form className="formContainer" onSubmit={handleSubmit}>
                        <Box className="btnsDiv">
                          <Button className="cancleBtn" data-test-id="cancelBtn"
                            onClick={() => {
                              this.handleCancleBtn();
                              handleReset();
                            }} >
                            Cancel
                          </Button>
                          <Button className="saveChangesBtn" type="submit" data-test-id="saveChangesBtn">
                            Save changes
                          </Button>
                        </Box>
                        {this.state.emptyDataError && (
                          <Alert
                            className="alertContainer"
                            severity="error"
                            icon={false}
                            style={{
                              display: 'block',
                              marginTop: '32px'
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                              {this.state.emptyDataError}
                              <IconButton style={{ marginLeft: 'auto', color: '#DC2626' }} size="small" onClick={() => this.setState({ emptyDataError: '' })}>
                                <CloseSharpIcon fontSize="inherit" />
                              </IconButton>
                            </div>
                          </Alert>
                        )}
                        <Box className="mainFormContainer">
                          <Box className="infoMainDiv">
                            <Box style={{ width: '30%' }}>
                              <Typography className="basicInfoTextbasic">Basic info</Typography>
                            </Box>
                            <Box style={{ width: '70%' }}>
                              <Typography className="textFieldTitles">Full name</Typography>
                              <TextField
                                value={this.state.fullName}
                                onChange={(e) => this.setState({ fullName: e.target.value })}
                                data-test-id="nameField"
                                type="text"
                                className="placeholderTexts"
                                autoComplete="off"
                                name="fullName"
                                placeholder="Your name"
                                variant="outlined"
                                fullWidth />
                              <ErrorMessage name="fullName" component="div" className="errorMessage" />
                              <Typography className="textFieldTitles">Birthday</Typography>
                              <Box style={{ position: "relative" }}>
                                <Datepicker
                                  ref={this.datePickerRef}
                                  selected={this.state.birthDate}
                                  onChange={this.handleDateChange}
                                  name="birthDate"
                                  data-test-id="birthDate"
                                  maxDate={new Date()}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dateFormat="MM/dd/yyyy"
                                  customInput={<input
                                    type="text"
                                    value={this.state.birthDateValue}
                                    onChange={this.handleInputChange}
                                    placeholder="MM/DD/YYYY"
                                    style={{ width: "100%", height: "52px", padding: "8px", boxSizing: "border-box" }}
                                  />}
                                />
                                <input
                                  type="text"
                                  value={this.state.birthDateValue}
                                  onChange={this.handleInputChange}
                                  data-test-id="birthdayInput"
                                  placeholder="MM/DD/YYYY"
                                  onClick={this.handleInputClick}
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "52px",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                  }}
                                />
                              </Box>
                              <ErrorMessage name="birthDate" component="div" className="errorMessage" />

                              <Typography className="textFieldTitles">E-mail</Typography>
                              <TextField
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                data-test-id="emailField"
                                type="text"
                                className="placeholderTexts"
                                autoComplete="off"
                                name="email"
                                placeholder="Your e-mail address"
                                variant="outlined"
                                fullWidth />
                              <ErrorMessage name="email" component="div" className="errorMessage" />
                              {this.state.apiError && (
                                <Typography component="div" className="errorMessage">
                                  {this.state.apiError}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          <div className="horizontalLine"></div>
                          <Box className="infoMainDiv">
                            <Box style={{ width: '30%' }}>
                              <Typography className="basicInfoTextbasiccontact">Contact information</Typography>
                            </Box>
                            <Box style={{ width: '70%' }}>
                              <Typography className="textFieldTitles">Contact number</Typography>
                              <PhoneInput
                                inputProps={{ name: "contactNumber" }}
                                data-test-id="contactNumber"
                                inputStyle={{ width: '100%', height: '11%' }}
                                country={"us"}
                                value={this.state.contactNumber}
                                onChange={(value) => this.setState({ contactNumber: value })} />
                              <ErrorMessage name="contactNumber" component="div" className="errorContactMessage" />
                              <Typography className="textFieldTitles" style={{ marginTop: '25px' }}>Address</Typography>
                              <TextField
                                value={this.state.address}
                                onChange={(e) => this.setState({ address: e.target.value })}
                                data-test-id="address"
                                type="text"
                                className="placeholderAddress"
                                autoComplete="off"
                                name="address"
                                placeholder="Your address"
                                variant="outlined"
                                fullWidth />
                              <ErrorMessage name="address" component="div" className="errorstateMessage" />
                              <Typography className="textFieldTitle" style={{ marginTop: '25px' }}>Zip code</Typography>
                              <TextField
                                value={this.state.zipCode}
                                onChange={(e) => {
                                  this.handleZipcodeChange(e);
                                  setFieldValue('zipCode', e.target.value);
                                }}
                                data-test-id="zipCode"
                                type="text"
                                className="placeholderTexts"
                                autoComplete="off"
                                name="zipCode"
                                placeholder="Your zip code"
                                variant="outlined"
                                fullWidth />
                              <ErrorMessage name="zipCode" component="div" className="errorMessage" />
                              {this.state.zipcodeError && (
                                <Alert className="errorAlerteMessage" icon={false}>
                                  {this.state.zipcodeError}
                                </Alert>
                              )}
                              <Box style={{ display: 'flex', gap: '2%', justifyContent: 'space-between', marginTop: '15px' }}>
                                <Box style={{ width: '48%' }}>
                                  <Typography className="textFieldTitle">State</Typography>
                                  <FormControl variant="outlined" style={{ marginBottom: '20px' }} fullWidth>
                                    <TextField
                                      data-test-id="state"
                                      value={this.state.state}
                                      onChange={(e) => {
                                        this.handleStateChange(e);
                                        setFieldValue('state', e.target.value);
                                      }}
                                      name="state"
                                      className="placeholderTextState"
                                      placeholder="Select the state"
                                      variant="outlined"
                                      autoComplete="off"
                                      disabled
                                      fullWidth />
                                    <ErrorMessage name="state" component="div" className="errorstateMessage" />
                                  </FormControl>
                                </Box>
                                <Box style={{ width: '48%' }}>
                                  <Typography className="textFieldTitle"> City</Typography>
                                  <FormControl variant="outlined" style={{ marginBottom: '20px' }} fullWidth>
                                    <TextField
                                      name="city"
                                      data-test-id="city"
                                      className="placeholderTexts"
                                      placeholder="Select a city"
                                      variant="outlined"
                                      autoComplete="off"
                                      disabled
                                      value={this.state.city}
                                      onChange={(e) => {
                                        this.handleCityChange(e);
                                        setFieldValue('city', e.target.value);
                                      }} />
                                    <ErrorMessage name="city" component="div" className="errorMessage" />
                                  </FormControl>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <div className="horizontalLine2"></div>
                          <Box className="infoMainDiv">
                            <Box style={{ width: '30%' }}>
                              <Typography className="basicInfoTextnotifications">Notifications</Typography>
                            </Box>
                            <Box style={{ width: '70%' }}>
                              <FormControlLabel
                                className="notificaionLableText"
                                labelPlacement='start'
                                label="Subscribe to newsletters, promotions, and updates from My Kinships."
                                control={<CustomizedButton
                                  data-test-id="handleToggleChangeBtn"
                                  checked={this.state.newsToggleChecked}
                                  onChange={(event) => this.handleToggleChange(event)} />} />
                            </Box>
                          </Box>
                          <div className="horizontalLine"></div>
                          <Button className="deleteBtnDiv" onClick={this.handleDeleteBtn} data-test-id="deleteBtn">
                            <DeleteOutlinedIcon />
                            <Typography className="deleteText">Delete Account</Typography>
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>

              <Popover
                data-test-id="handleCloseEditPhoto"
                className="photoModal"
                open={this.state.EditPhotoClick}
                onClose={this.handleCloseEditPhoto}
                style={{
                  height: "400px",
                  top: 470,
                  left: 330,
                  position: 'absolute'
                }}
              >
                <PopoverPhotoContent>
                  <Box style={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                    <Button style={{ textTransform: 'none' }} data-test-id="openUploadModal" onClick={this.openUploadModal}>
                      <FileUploadOutlinedIcon className="photoListIcon" />
                      <Typography className="photoText">Upload photo</Typography>
                    </Button>

                    <Button style={{ textTransform: 'none' }} data-test-id="handleUploadLibrary" onClick={this.openUploadModal}>
                      <FolderOpenIcon className="photoListIcon" />
                      <Typography className="photoText">Upload from Library</Typography>
                    </Button>

                    <Button style={{ textTransform: 'none' }} data-test-id="handleCaptureEnable" onClick={this.handleCaptureEnable}>
                      <CameraAltOutlinedIcon className="photoListIcon" />
                      <Typography className="photoText"> Take a picture</Typography>
                    </Button>
                    <Button style={{ textTransform: 'none' }} data-test-id="handleRemovePhotoBtn" onClick={this.handleRemovePhotoBtn}>
                      <DeleteOutlineIcon className="photoListIcon" />
                      <Typography className="photoText">Remove photo</Typography>
                    </Button>
                  </Box>
                </PopoverPhotoContent>
              </Popover>

              <Dialog className="dialogContainer" data-test-d="handleChangeModal" open={this.state.showModal} onClose={this.closeDeleteModel} PaperProps={{
                style: {
                  position: 'relative',
                  height: 'auto',
                  width: '540px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderRadius: "8px 8px 32px 8px",
                }
              }} >
                <DialogTitle>
                  <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Delete account</Typography>
                  <IconButton data-test-id='deleteIcon' className="closeIcon" onClick={this.closeDeleteModel} style={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#666',
                  }}><CloseIcon /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogBoxContent className="dialogContainerMain">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box style={{ display: 'flex', margin: '20px 0 20px 0', padding: '5px', flexDirection: 'column', alignItems: 'center' }}>
                      <Box className="modalMainDivs">
                        <DeleteOutlineIcon style={{ color: 'white', height: '65px', width: '65px' }} />
                      </Box>
                      <Box style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px', marginTop: '40px' }}>
                        <Typography className="mainTextModal">Are you sure you want to delete your account?</Typography>
                        <Typography className="subTextModal">If you proceed to delete your account, you will have the option to recover it for a period of 30 days, after that, it will be completely deleted.</Typography>
                      </Box>
                    </Box>
                  </div>
                </DialogBoxContent>
                <Divider />
                <DialogActions style={{ display: 'flex', justifyContent: 'center', gap: '10px', paddingBottom: 30, margin: '20px 10px 20px 10px' }}>
                  <Button onClick={this.closeDeleteModel} className="keepBtn" data-test-id="keepBtn" style={{
                    border: '1px solid #C2E176',
                    backgroundColor: '#F8FFE7',
                    width: '233px',
                    height: '56px',
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform: "none",
                    fontFamily: "Poppins",
                    lineHeight: "24px",
                    color: '#8CA74B'
                  }}>Keep it</Button>
                  <Button onClick={this.handleConfirmDeleteBtn} className="deleteBtn" data-test-id="handleDeleteBtn" style={{
                    backgroundColor: '#8CA74B',
                    width: '233px',
                    height: '56px',
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform: "none",
                    fontFamily: "Poppins",
                    lineHeight: "24px",
                    color: 'white'
                  }}>Yes, delete it</Button>
                </DialogActions>
              </Dialog>

              <Dialog data-test-d="handleConfirmDeleteModal" open={this.state.showDeleteConfirmModal} onClose={this.closeDeleteConfirmModal} PaperProps={{
                style: {
                  position: 'relative',
                  height: 'auto',
                  width: '540px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderRadius: 8,
                  borderBottomRightRadius: 50,
                  
                }
              }} >
                <DialogTitle>
                  <Typography style={{ fontSize: 20, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110' }}>Delete account</Typography>
                  <IconButton data-test-id='deleteIcon' className="closeIcon" onClick={this.closeDeleteConfirmModal} style={{ position: 'absolute', right: 8, top: 8, color: '#666', }}><CloseIcon /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogDeleteBoxContent className="dialogContainerMain">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box style={{ display: 'flex', margin: '20px 0 20px 0', flexDirection: 'column', alignItems: 'center', padding: '5px' }}>
                      <Box className="modalMainDiv">
                        <DeleteOutlineIcon style={{ color: 'white', width: '65px', height: '65px' }} />
                      </Box>
                      <Box style={{ textAlign: 'center', marginLeft: '10px', marginBottom: '20px', marginRight: '10px', marginTop: '40px' }}>
                        <Typography className="mainTextModal">The account has been successfully deleted</Typography>
                      </Box>
                    </Box>
                  </div>
                </DialogDeleteBoxContent>
              </Dialog>

              <Popover
                className="dialogContainer"
                open={this.state.uploadMedia}
                onClose={this.closeUploadModal}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
              >
                <PopoverProfileContent>
                  <Box className="headerProfileBox">
                    <Typography className="pictureText">Profile picture selection</Typography>
                    <IconButton
                      data-test-id='closeIcon'
                      className="closeIcon"
                      onClick={this.closeUploadModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Divider style={{ marginTop: '10px', marginBottom: '25px', color: '#E7E5E4' }} />
                  <Box
                    data-test-id="mediaDragBox"
                    className="imgInnerBox"
                    onDrop={this.handleMediaDrop}
                    onDragOver={this.handleMediaDragOver}
                    onDragLeave={this.handleMediaDragLeave}
                    onDragEnter={this.handleMediaDragEnter}
                  >
                    <input
                      data-test-id="handleFileInput"
                      type="file"
                      ref={this.fileInputRef}
                      style={{ display: 'none' }}
                      onChange={this.handleFileChange}
                      accept="image/*" />
                    {this.state.userImg ? (
                      <img src={this.state.userImg} alt="Selected" style={{ width: '152px', height: '152px' }} />
                    ) : (
                      <>
                        <img src={imgUpload} />
                        <Typography className="uploadImgText">Upload image</Typography>
                        <Typography className="dragImgText" style={{ marginTop: '5px' }}> Formats supported: JPG, PNG, GIF, TIFF, HEIF</Typography>
                        <Typography className="dragImgText" style={{ marginTop: '13px' }}>Drag an image here or</Typography>
                        <Button className="browseBtn" data-test-id="browseBtn" onClick={this.handleUploadButtonClick}>
                          <AddCircleOutlineOutlinedIcon style={{ height: '17px', width: '17px', color: '#557C29' }} />
                          <Typography className="browseFileText">Browse file to upload</Typography>
                        </Button>
                      </>
                    )}
                  </Box>
                  <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button className="cancelBtn" onClick={this.closeUploadModal}>
                      Cancel
                    </Button>
                    <Button className="saveBtn" data-test-id="saveProfileClickBtn" onClick={() => this.saveProfileClick(this.state.userImg)}>
                      Save as profile picture
                    </Button>
                  </Box>
                </PopoverProfileContent>
              </Popover>

              <Dialog open={this.state.showMediaModal} onClose={this.handleMediaCloseModal} className="dialogContainer" PaperProps={{
                style: {
                  position: 'relative',
                  height: 'auto',
                  width: '680px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  borderRadius: 8,
                  borderBottomRightRadius: 50,
                }
              }} >
                <DialogTitle>
                  <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Unsupported format or image size</Typography>
                  <IconButton onClick={this.handleMediaCloseModal}
                    aria-label="close"
                    style={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: '#666',
                    }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <Divider />
                <DialogBoxContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box style={{
                      textAlign: 'center',
                      backgroundColor: '#F87171',
                      borderRadius: '80px',
                      height: '130px',
                      width: '130px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '25px'
                    }}>
                      <img src={fail} style={{ color: 'white', height: '65px', width: '65px' }} />
                    </Box>
                  </div>
                  <Box style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px', marginTop: '40px' }}>
                    <Typography style={{
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      fontSize: "20px",
                      lineHeight: "28px",
                      color: "#1C2110",
                      alignItems: 'center'
                    }} >Unable to read your files.</Typography>
                    <Typography style={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#1C2110",
                      alignItems: 'center',
                      marginTop: '30px'
                    }}>Your photos couldn't be uploaded. Photos should be less than X MB and saved as JPG, PNG, GIF, TIFF, or HEIF files. </Typography>
                  </Box>
                </DialogBoxContent>
                <Divider />
                <DialogActions style={{ display: 'flex', justifyContent: 'center', paddingBottom: 30, marginTop: '15px' }}>
                  <Button data-test-id="handleMediaCloseModal" onClick={this.handleMediaCloseModal} style={{
                    border: '1px solid #C2E176',
                    backgroundColor: '#F8FFE7',
                    width: '300px',
                    height: '56px',
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform: "none",
                    fontFamily: "Poppins",
                    lineHeight: "24px",
                    color: '#8CA74B'
                  }}>Cancel</Button>
                  <Button onClick={this.tryAgainClick} style={{
                    backgroundColor: '#8CA74B',
                    width: '300px',
                    height: '56px',
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform: "none",
                    fontFamily: "Poppins",
                    lineHeight: "24px",
                    color: 'white'
                  }}  >Try again </Button>
                </DialogActions>
              </Dialog>

            </>
          )}

        </UserProfileStyle>
        {!this.state.isCaptureEnable ? (
          <Footer navigation={this.props.navigation} id={""} />
        ) : (
          null
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const DialogBoxContent = styled("div")({
  "& .modalMainDivs": {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: '#8CA74B',
    borderRadius: '75px',
    justifyContent: 'center',
    height: '100px',
    width: '100px',
  },
  "& .keepBtn": {
    backgroundColor: '#F8FFE7',
    height: '44px',
    width: '180px',
    borderRadius: '8px',
    border: '1px solid #C2E176',
    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      color: '#8CA74B',
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
    }
  },
  "& .deleteBtn": {
    width: '180px',
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    height: '44px',
    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      color: 'white',
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
    }
  },
  "& .mainTextModal": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: '#1C2110',
    marginLeft: '20px', marginRight: '20px'
  },
  "& .subTextModal": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginLeft: '6px',
    marginRight: '6px',
    marginTop: '16px'
  },
  "& .dialogContainerMain": {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  '@media (min-width: 1280px) and (max-width: 1920px)': {
    "& .modalMainDivs": {
      width: '120px',
      height: '120px',
    }
  },
  '@media (min-width: 360px) and (max-width: 414px)': {
    "& .modalMainDivs": {
      width: '80px',
      height: '80px',
    }
  },
  '@media (min-width: 601px) and (max-width: 1280px)': {
    "& .modalMainDivs": {
      width: '100px',
      height: '100px',
    }
  }
});


export const PopoverProfileContent = styled(Box)(() => ({
  width: "400px",
  height: 'auto',
  padding: "20px",
  alignItems: "center",
  "@media(max-width: 768px)": {
    padding: "10px",
    height: "240px !important",
  },
  "& .headerProfileBox": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  "& .pictureText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: '#000000',
   letterSpacing: '0.5%'
  },
  "& .imgInnerBox": {
    border: '2px dashed #D6D3D1',
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  "& .uploadImgText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
  },
  "& .dragImgText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#202020',
  },
  "& .browseBtn": {
    '& .MuiButton-label': {
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: '#557C29',
      textTransform: 'none'
    }
  },
  "& .browseFileText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#557C29',
    marginLeft: '2px'
  },
  "& .yourPhotosText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginTop: '20px'
  },
  "& .imgsBox": {
    display: 'flex',
    gap: '16px',
    marginTop: '10px'
  },
  "& .viewBtn": {
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "none",
    fontFamily: "Poppins",
    lineHeight: "22px",
    color: '#557C29',
    '& .MuiButton-label': {
      textTransform: 'none'
    }
  },
  "& .cancelBtn": {
    borderRadius: '8px',
    border: '1px solid #C2E176',
    backgroundColor: '#F8FFE7',
    width: '180px',
    height: '44px',
    '& .MuiButton-label': {
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 700,
      color: '#8CA74B',
      fontFamily: "Poppins",
      lineHeight: "24px",
    }
  },
  "& .saveBtn": {
    width: '180px',
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    height: '44px',
    '& .MuiButton-label': {
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: "Poppins",
      textTransform: "none",
      color: 'white',
      lineHeight: "24px",
    }
  },
  '@media (min-width: 1280px) and (max-width: 1920px)': {
    width: '450px',
    padding: '24px',
  },
  '@media (min-width: 360px) and (max-width: 414px)': {
    width: '300px',
    padding: '12px',
  },
  '@media (min-width: 601px) and (max-width: 1280px)': {
    width: '350px',
    padding: '16px',
  },


  "& .uploadImgText, & .dragImgText, & .browseFileText, & .yourPhotosText, & .viewBtn, & .cancelBtn, & .saveBtn": {
    fontFamily: "Poppins",
    fontWeight: 700,
  }
}));

export const PopoverPhotoContent = styled(Box)(() => ({
  padding: '5px',
  marginRight: '30px',
  "& .photoText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A",
    padding: '6px',
    marginLeft: '5px',
  },
  "& .photoListIcon": {
    height: '18px',
    width: '18px'
  }
}));

export const DialogDeleteBoxContent = styled(Box)(() => ({
  display: 'flex',
  padding: "10px",
  alignItems: "center",
  flexDirection: "column",
  position: 'relative',
  "@media(max-width: 768px)": {
    padding: "5px",
    height: "240px !important",
  },
  "& .closeIcon": {
    height: '14px',
    width: '14px',
    color: '#44403C'
  },
  "& .mainTextModal": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: '#1C2110',
    marginLeft: '20px',
    marginRight: '20px'
  },
  "& .modalMainDiv": {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: '#34D399',
    borderRadius: '75px',
    justifyContent: 'center',
    height: '100px',
    width: '100px',
  },
  "& .dialogContainerMain": {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
   '@media (min-width: 1280px) and (max-width: 1920px)': {
    padding: '20px',
  },
  '@media (min-width: 360px) and (max-width: 414px)': {
    padding: '8px',
    height: '220px !important',
  },
  '@media (min-width: 601px) and (max-width: 1280px)': {
    padding: '12px',
    height: '250px !important',
  },
}));

const UserProfileStyle = styled("div")({
  "& .headerDiv": {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    borderBottom: '1px solid #E7E5E4'
  },
  "& .profileDiv": {
    display: 'flex'
  },
  "& .profileBreadcrumps": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#57534E",
    textTransform: 'none'
  },
  "& .editProfileText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2110",
    textTransform: 'none'
  },
  "& .placeholderAddress": {
    marginTop: '5px'
  },
  "& .errorstateMessage": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    marginBottom: '10px'
  },
  "& .errorAlerteMessage": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    marginBottom: '10px',
    backgroundColor: 'white',
    marginTop: '-25px',
    marginLeft: '-15px'
  },
  "& .titleText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "40px",
    letterSpacing: "-0.5%",
    color: "#1C1917",
  },
  "& .photoModal": {
    padding: '30px'
  },
  "& .topBox": {
    backgroundColor: '#8CA74B',
    height: '217px',
    border: '1px solid #8CA74B',
    borderBottomRightRadius: '32px',
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  "& .mainDiv": {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  "& .innerDiv": {
    width: '70%',
  },
  "& .navigationBox": {
    padding: '25px',
    marginLeft: '70px'
  },
  "& .editProfileBox": {
    display: 'flex',
    marginLeft: '30px'
  },
  "& .cancleBtn": {
    border: '1px solid #C2E176',
    backgroundColor: '#F8FFE7',
    width: '148px',
    height: '44px',
    borderRadius: '8px',

    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: '#8CA74B'
    }
  },
  "& .saveChangesBtn": {
    backgroundColor: '#8CA74B',
    width: '148px',
    height: '44px',
    borderRadius: '8px',
    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: 'white'
    }
  },
  "& .btnsDiv": {
    display: 'flex',
    justifyContent: 'end',
    gap: '25px',
    marginTop: '-70px',
    marginRight: '39px'
  },
  "& .infoMainDiv": {
    display: 'flex'
  },
  "& .basicInfoTextbasic":{
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
     color: '#5D6063'
  },
  "& .basicInfoTextbasiccontact": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
     color: '#5D6063'
  },

  "& .basicInfoTextnotifications": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
     color: '#5D6063'
  },
  "& .basicInfoText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#5D6063'
  },
  "& .textFieldTitles": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginTop: '15px'
  },
  "& .textFieldTitle": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginTop: '5px'
  },
  "& .notificaionLableText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#1C2110',
    display: 'flex',
    justifyContent: 'space-between'
  },
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
    marginRight: 0,
  },
  "& .deleteBtnDiv": {
    color: '#DC2626',
    marginBottom: '65px',
    '& .MuiButton-label': {
      textTransform: 'none'
    }
    ,'@media (max-width: 414px)': {
      marginLeft: '55px',
    }
  },
  "& .deleteText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#DC2626'
  },
  "& .profilePhoto": {
    height: '152px',
    width: '152px',
    borderRadius: '80px',
    objectFit: 'cover'
  },
  "& .profilePhotoName": {
    height: '150px',
    width: '151px',
    borderRadius: '80px',
    color: '#8CA74B',
    fontStyle: 'Inter',
    backgroundColor: '#F8FFE7',
    fontSize: '56px',
    border: '4px solid #8CA74B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  "& .avatarImgBox": {
    border: '4px solid white',
    borderRadius: '80px',
    height: '152px',
    width: '152px',
    position: 'relative',
  },
  "& .alertContainer": {
    borderRadius: '4px',
    border: '0px, 0px, 0px, 4px',
    borderLeft: '4px solid #DC2626',
    padding: "12px, 16px, 12px, 16px",
    fontSize: "12px",
    foneFamily: "Poppins",
    fontWeight: 400,
    marginLeft: '40px',
    marginRight: '39px',
    marginBottom: '20px'
  },
  "& .alertPhotoContainer": {
    borderRadius: '4px',
    border: '0px, 0px, 0px, 4px',
    borderLeft: '4px solid #DC2626',
    padding: "12px, 16px, 12px, 16px",
    fontSize: "12px",
    foneFamily: "Poppins",
    fontWeight: 400,
  },
  "& .cameraIConBox": {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#0000008a",
    borderRadius: '80px',
    height: '151px',
    width: '151px',
    display: 'flow',
    transform: "translate(48px, -77px)",
    '& .MuiButton-label': {
      textTransform: 'none'
    }
  },
  "& .editIconBox": {
    cursor: 'pointer',
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "#0000008a",
    borderRadius: '50%',
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiButton-label': {
      textTransform: 'none'
    }
  },
  "& .editPicText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#FFFFFF'
  },
  "& .mainFormContainer": {
    marginLeft: '40px',
    marginRight: '39px'
  },
  "& .placeholderTexts": {
    marginBottom: '15px',
    marginTop: '5px'
  },
  "& .placeholderTextState": {
    marginTop: '5px'
  },
  "& .horizontalLine": {
    borderBottom: '1px solid #E2E8F0',
    borderRadius: '0.5px',
    marginTop: '32px',
    marginBottom: '32px'
  },
  "& .horizontalLine2": {
    borderBottom: '1px solid #E2E8F0',
    borderRadius: '0.5px',
    marginTop: '10px',
    marginBottom: '32px'
  },
  "& .errorMessage": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    marginTop: '-12px'
  },
  "& .errorContactMessage": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    marginTop: '2px'
  },
  "& .errorEmail": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: '#DC2626',
    backgroundColor: 'white'
  },
  "& .deleteIconBox": {
    borderRadius: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    backgroundColor: '#8CA74B',
    height: '125px',
    width: '125px'
  },
  "& .dialogContainer": {
    boderRadius: '8px 8px 32px 8px',
    backgroundColor: 'white',
    height: '577px',
    width: '600px',
    "& .MuiPopover-paper": {
      boderRadius: '8px 8px 32px 8px',
      backgroundColor: 'white',
      height: '577px',
      width: '600px'
    },
  },
  "& .cameraText": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: '#1C2110',
    letterSpacing: '-0.12%'
  },
  "& .cameraBox": {
    display: 'flex',
    alignItems: 'center'
  },
  "& .bottomCameraDiv": {
    height: '150px',
    marginTop: '20px',
    backgraoungColor: 'gray',
    display: 'flex',
    justifyContent: 'center'
  },
  "& .photoTextTypo": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#8CA74B',
    alignItems: 'center',
    marginLeft: '8px'
  },
   '@media (min-width: 1280px) and (max-width: 1920px)': { 
    "& .innerDiv": { width: '75%' },
    "& .titleText": { fontSize: '32px' }
  },
  '@media (min-width: 360px) and (max-width: 896px)': { 
    "& .headerDiv": {padding: '10px' },
    "& .innerDiv": { width: '95%' },
    "& .titleText": { fontSize: '20px', lineHeight: '28px' },
    "& .basicInfoTextbasic": { marginTop:"14px",marginLeft:"-21px" },
    "& .basicInfoTextbasiccontact": { marginTop:"-35px",marginLeft:"-21px" },
    "& .basicInfoTextnotifications": {marginLeft:"-24px"},
    "& .MuiFormControlLabel-labelPlacementStart":{marginLeft:"12px"}
  },
  '@media (min-width: 601px) and (max-width: 1280px)': {
    "& .innerDiv": { width: '85%' },
    "& .titleText": { fontSize: '24px' }
  }
})
// Customizable Area End
import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography
} from "@material-ui/core";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import HeaderDropdown from "../../../blocks/navigationmenu/src/HeaderDropdown.web";
import { photosIcon } from "./assets";
// Customizable Area End

import PhotoLibraryController, {
  Props,
  configJSON
} from "./PhotoLibraryController";

// Customizable Area Start
import { SharingDashboardZeroStyle } from "../../../blocks/dashboard/src/SharingDashboard.web";
import Uploadmedia3 from "../../../blocks/uploadmedia3/src/Uploadmedia3.web";
// Customizable Area End

export default class PhotoLibrary extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <SharingDashboardZeroStyle>
        <NavigationMenu navigation={this.props.navigation} id={""} selectedPage="Photos" />
        <Box className="MainContent">
          <Box className="Header">
            <HeaderDropdown
              data-test-id="headerComponent" navigation={this.props.navigation} id={""}
            />
          </Box>
          <Typography className="page-title">
          Photos
          </Typography>
            <Box className="centerDiv">
              <img className="img" src={photosIcon} alt="Trash Icon" />
              <Typography className="welcomeTitle">No Photos yet</Typography>
              <Typography className="sublabel">Once you upload your content such as photos or videos. You'll be able to view them here.</Typography>
              <Uploadmedia3 navigation={this.props.navigation} id={""}/>
            </Box>
        </Box>
      </SharingDashboardZeroStyle>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End

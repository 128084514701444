import React from "react";

import {
    Box,
    Button,
    Typography,
    IconButton,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import { BackArrowBlack, download, kinshipslogo } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// Customizable Area End

import PaymentController, {
    Props
} from "./PaymentController.web";

// Customizable Area Start
// Customizable Area End

export default class PaymentDetails extends PaymentController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <PaymentStyle>
                <HeaderDropdown data-test-id="headerComponent" navigation={this.props.navigation} id={""} />
                <Box className="nav-container" >
                    <Breadcrumbs style={{ marginLeft: '70px' }} separator={<NavigateNextIcon fontSize="small" />}>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton">
                            Settings
                        </Button>
                        <Button onClick={() => { this.handleSubscription() }} className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButtonTest">
                            Subscription
                        </Button>
                        <Button onClick={() => { this.handlePayments() }} className="breadcrumbsButton" color="inherit" data-test-id="handlePaymentsTest">
                            Payments
                        </Button>
                        <Typography className="breadcrumbsText">
                            See details
                        </Typography>
                    </Breadcrumbs>
                    <Box className="top-title">
                        <Button className="backButton" onClick={this.subscriptionClickBtns}>
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleText">Payment details</Typography>
                    </Box>
                    <Box
                        style={{
                            border: '1px solid #8CA74B',
                            borderRadius: '4px',
                            padding: '16px',
                            maxWidth: '1016px',
                            width: '100%',
                            margin: 'auto',
                            backgroundColor: '#f9f9f9',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '16px',
                            marginTop: 50
                        }}
                    >

                        <Box
                            className="handle-download-btn"
                            onClick={this.handleDownload}
                        >
                            <img
                                src={download}
                                alt="Download"
                                style={{ width: 11, height: 11, marginRight: 4, marginTop: 2 }}
                            />
                            <Typography
                                style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 700,
                                    fontSize: 14,
                                    color: '#8CA74B',
                                }}
                            >
                                Download PDF
                            </Typography>
                        </Box>

                        <div ref={this.pdfRef} style={{ paddingTop: 20 }}>
                            <Box className="payment-details-container">
                                <img src={kinshipslogo} style={{ width: 80, height: 41 }} />

                                <Box className="detail-component-container">
                                    <Typography style={{ fontSize: 16, fontWeight: 700, fontFamily: 'Poppins' }}>My Kinships</Typography>
                                    <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 5 }}>123 Hug Lane, Digital City, DC 56789, United States</Typography>
                                </Box>
                                <Box className="detail-component-container" >
                                    <Typography style={{ fontSize: 16, fontWeight: 700, fontFamily: 'Poppins' }}>Bill to</Typography>
                                    <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 5 }}>{this.state.PaymentDetails.billing_details?.full_name}</Typography>

                                    <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins' }}>56789
                                    </Typography>
                                    <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins' }}>United States</Typography>
                                </Box>
                                <Box className="detail-component-container">
                                    <Typography style={{ fontSize: 16, fontWeight: 700, fontFamily: 'Poppins' }}>
                                        Invoice information
                                    </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box className="invoice-details-container" sx={{ marginRight: '16px' }}>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 9, whiteSpace: "nowrap" }}>Invoice number </Typography>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 2, whiteSpace: "nowrap" }}>Invoice date</Typography>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 2, whiteSpace: "nowrap"  }}>Payment Terms</Typography>
                                            <Typography className="invoice-id">Order number</Typography>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 2, whiteSpace: "nowrap"  }}>Currency</Typography>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 2, whiteSpace: "nowrap"  }}>Total</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 9 }}> {this.state.PaymentDetails?.invoice_details?.invoice_number || 'N/A'} </Typography>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 2 }}>
                                                {this.state.PaymentDetails?.current_subscription?.start_date ? new Date(this.state.PaymentDetails.current_subscription.start_date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) : 'N/A'} </Typography>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 2 }}> {this.state.PaymentDetails?.current_subscription?.bank_details === 'bank_details' ? 'ACH' : 'Credit Card'} </Typography>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 2, wordBreak: "break-all" }}> {this.state.PaymentDetails?.invoice_details?.id || 'N/A'} </Typography>
                                            <Typography style={{ maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 2 }}> {this.state.PaymentDetails?.invoice_details?.currency?.toUpperCase() || 'USD'} </Typography>
                                            <Typography style={{ maxWidth: 230, fontWeight: 700, fontSize: 12, fontFamily: 'Poppins', marginTop: 2 }}> ${this.state.PaymentDetails?.current_subscription?.amount || '0.00'} </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                </Box>
                            </Box>
                        </div>
                    </Box>

                </Box>
                <Box className="bottom-container">
                    <Footer navigation={this.props.navigation} id={""} />
                </Box>
            </PaymentStyle >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const PaymentStyle = styled("div")({
    overflowX: 'hidden',
    "& .headerDiv": {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '1px solid #E7E5E4'
    },
    "& .invoice-details-container .invoice-id": {
        maxWidth: 230, fontWeight: 400, fontSize: 12, fontFamily: 'Poppins', marginTop: 2, whiteSpace: "nowrap",
        '@media (max-width: 410px)': {
            marginBottom: 20
        },
    },
    "& .bottom-container": {
        marginTop: 700,
        '@media (max-width: 900px)': {
            marginTop: 500
        },
        '@media (max-width: 750px)': {
            marginTop: 300
        },
        '@media (max-width: 550px)': {
            marginTop: 200
        },
    },
    "& .detail-component-container": {
        display: 'flex', flexDirection: 'column', marginTop: 5,
        '@media (max-width: 900px)': {
            marginTop: 25
        },
    },
    "& .payment-details-container": {
        display: 'flex', justifyContent: 'space-between', marginLeft: 20,
        '@media (max-width: 900px)': {
            flexDirection: "column"
        },
    },
    "& .nav-container": {
         padding: '30px', marginLeft: '20px', marginRight: '20px', 
        '@media (max-width: 550px)': {
            marginLeft: "0px !important"
        },
    },
    "& .nav-container .MuiBreadcrumbs-root": {
        '@media (max-width: 780px)': {
            marginLeft: "0px !important"
        },
        "& .MuiBreadcrumbs-separator": {
            '@media (max-width: 550px)': {
                marginLeft: "0px !important",
                marginRight: "0px !important"
            },
        }
    },
    "& .top-title": {
        display: 'flex', marginTop: 25, marginLeft: 10,
        '@media (max-width: 780px)': {
            marginLeft: "-20px"
        },
    },
    "& .handle-download-btn": {
        display: 'flex', justifyContent: 'flex-end', cursor: 'pointer',
        '@media (max-width: 900px)': {
            justifyContent: "center"
        },
    },
    "& .profileDivDetails": {
        display: 'flex'
    },
    "& .titleText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
        letterSpacing: "-0.5%",
        color: "#1C2110",
        '@media (max-width: 779px)': {
            fontSize: "24px",
        },
        '@media (max-width: 550px)': {
            fontSize: "20px",
        },
    },
    "& .breadcrumbsButton": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#57534E",
        "& .MuiButton-label": {
            textTransform: "none",
        },
        '@media (max-width: 550px)': {
            lineHeight: "10px",
        },
    },
    "& .breadcrumbsText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
});
// Customizable Area End
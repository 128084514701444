Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling";
exports.labelBodyText = "subscriptionbilling Body";

exports.btnExampleTitle = "CLICK ME";
exports.createSubscriptionEndPoint = "bx_block_subscriptionbilling/create_subscription";
exports.createSubscriptionAchEndPoint = "bx_block_subscriptionbilling/bank_account_payment_subscription"
exports.getSubscriptionEndPoint = "bx_block_subscriptionbilling/get_payment_success"
exports.getBankSubscriptionEndPoint = "bx_block_subscriptionbilling/payment_details"
exports.createSubscriptionApiMethodType = "POST";
exports.createSubscriptionAchApiMethodType = "POST";
exports.apiMethodType = "PATCH";
exports.deleteApiType = "DELETE"
exports.getDeleteCreditCardEndPoint = "bx_block_subscriptionbilling/delete_card";
exports.getDeleteBankEndPoint = "bx_block_subscriptionbilling/delete_bank";
exports.getCardDetails = "bx_block_subscriptionbilling/get_card";
exports.getBankDetails = "bx_block_subscriptionbilling/get_bank";
exports.getBillingAddressEndPoint = "bx_block_subscriptionbilling/billing_addresses";
exports.getBillingAddressApiMethodType = "POST"
exports.getAllSubscriptionEndPoint = "bx_block_subscriptionbilling/subscription_plan";
exports.getAllPlansEndPoint = "bx_block_subscriptionbilling/subscription_plan/find_by_name";
exports.getSubscriptionBillingFaqEndPoint = "account_block/question_and_answer";
exports.deleteCardAchEndPoint = "bx_block_subscriptionbilling/detach_payment_method";
exports.deleteBankOrCardEndPoint = "bx_block_subscriptionbilling/get_payment_methods";
exports.userAccountDetailsEndPoint = "account_block/accounts";
exports.zipCodeDetailsEndPoint = "account_block/identify_location";
exports.remainingStorageEndpoint = "bx_block_bulk_uploading/total_storage_used";
exports.bankNames = [
  "Bank of America",
  "Chase",
  "Wells Fargo",
  "Citi",
  "Capital One",
  "PNC Bank",
  "US Bank",
  "TD Bank",
  "BB&T",
  "SunTrust",
  "Fifth Third Bank",
  "HSBC",
  "Charles Schwab",
  "Ally Bank",
  "KeyBank",
  "Regions Bank",
  "Santander Bank",
  "BMO Harris Bank",
  "Huntington Bank",
  "M&T Bank",
  "Citizens Bank",
  "American Express",
  "Navy Federal Credit Union",
  "USAA",
  "Synchrony Bank",
  "Comerica",
  "Zions Bank",
  "First Republic Bank",
  "Silicon Valley Bank",
  "Discover Bank",
  "Goldman Sachs Bank USA",
  "Morgan Stanley Bank",
  "TD Ameritrade",
  "E*TRADE Bank",
  "Barclays Bank",
  "BancorpSouth",
  "BOK Financial",
  "Frost Bank",
  "Bank of the West",
  "BMO Financial Group",
  "Webster Bank",
  "Old National Bank",
  "UMB Financial Corporation",
  "People's United Financial",
  "FNB Corporation",
  "Pinnacle Financial Partners",
  "First National Bank",
  "Valley National Bank",
  "City National Bank",
  "East West Bank",
  "Cullen/Frost Bankers",
  "Synovus Bank",
  "Bank OZK",
  "Prosperity Bank",
  "Texas Capital Bank",
  "Wintrust Financial",
  "South State Bank",
  "Independent Bank",
  "Renasant Bank",
  "BankUnited",
  "First Citizens Bank",
  "United Community Bank",
  "Cadence Bank",
  "Western Alliance Bank",
  "Banner Bank",
  "Columbia Bank",
  "Great Western Bank",
  "Pacific Premier Bank",
  "First Horizon Bank",
  "Central Bank",
  "Southwest Bank",
  "Commerce Bank",
  "TowneBank",
  "Heritage Bank",
  "Bank of Hawaii",
  "BancFirst",
  "Glacier Bank",
  "Simmons Bank",
  "UMB Bank",
  "Heartland Bank",
  "Tri Counties Bank",
  "Trustmark National Bank",
  "First Financial Bank",
  "First Interstate Bank",
  "Provident Bank",
  "City Bank",
  "Hanmi Bank",
  "Mechanics Bank",
  "NBT Bank",
  "OceanFirst Bank",
  "Old Second National Bank",
  "Park National Bank",
  "Peoples Bank",
  "Pinnacle Bank",
  "PlainsCapital Bank",
  "PNC Bank",
  "Popular Bank",
  "Rabobank",
  "Raymond James Bank",
  "Republic Bank",
  "Rockland Trust",
  "Santander Bank",
  "Scotiabank",
  "Simmons Bank",
  "Southern Bank",
  "Sterling National Bank",
  "Synovus Bank",
  "TCF Bank",
  "TD Bank",
  "The Northern Trust Company",
  "Trustmark Bank",
  "UMB Bank",
  "Union Bank",
  "United Bank",
  "Valley National Bank",
  "Webster Bank",
  "Wells Fargo",
  "Western Alliance Bank",
  "Wintrust Bank",
  "Zions Bank"
];
// Customizable Area End
import React from "react";

import {
  Box,
  Button,
  Typography,
  IconButton,
  Divider,
  Dialog,
  styled,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Toaster } from "react-hot-toast";
import { Alert } from '@material-ui/lab';
// Customizable Area End

import Uploadmedia3Controller, {
  Props,
  configJSON,
} from "./Uploadmedia3Controller";
import { deleteIcon, fileErrorIcon, uploadIcon } from "./assets";

export default class Uploadmedia3 extends Uploadmedia3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Button className="shareBtn" data-test-id="upload-photo-btn" onClick={this.openUploadModal}
        ><Typography className="shareBtnText">Start Uploading</Typography></Button>
        <Dialog
          className="dialogContainer"
          data-test-d="handleChangeModal"
          maxWidth="sm"
          fullWidth
          open={this.state.uploadModalOpen}
          onClose={this.closeUploadModal}
          PaperProps={{
            style: {
              position: 'relative',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              borderRadius: "8px 8px 32px 8px",
              scrollbarWidth: "thin",
              scrollbarColor: "#8CA74B transparent"
            }
          }} >
          <Toaster />
          <DialogProfileContent>
            <Box className="headerProfileBox">
              <Typography className="pictureText">Upload files</Typography>
              <IconButton
                data-test-id='closeIcon'
                className="closeIcon"
                onClick={this.closeUploadModal}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider style={{ marginTop: '10px', marginBottom: '25px', color: '#E7E5E4' }} />
            {!this.state.isUploadInProgress ? <>
              <div
                data-test-id="mediaDragBox"
                className="imgInnerBox"
                onDrop={this.handleDrop}
                onDragOver={this.handleDragOver}
              >
                <input
                  data-test-id="handleFileInput"
                  type="file"
                  ref={this.fileInputRef}
                  style={{ display: 'none' }}
                  onChange={this.handleFileChange}
                  multiple
                  accept="image/*, video/*" />

                <img src={uploadIcon} style={{
                  marginTop: "20px",
                  marginBottom: "20px"
                }} />
                <Typography className="uploadImgText">Upload your files here</Typography>
                <Typography className="dragImgText" style={{ fontWeight: 400, marginTop: "5px" }}>Drag images here or</Typography>
                <Button className="browseBtn" data-test-id="browseBtn" onClick={this.handleUploadButtonClick}>
                  <AddCircleOutlineOutlinedIcon style={{ height: '17px', width: '17px', color: '#8CA74B', marginRight: "5px" }} />
                  <Typography className="browseFileText">Browse file to upload</Typography>
                </Button>
              </div>
              <Box className="upload-images-container" >
                {this.state.sizeWarningFlag && (
                  <Alert
                    className="alertContainer"
                    severity="warning"
                    icon={false}
                    style=
                    {{
                      marginTop: '32px',
                      marginBottom: "32px",
                      backgroundColor: "#FEF3C7",
                      color: "#D97706",
                      borderLeft: '4px solid #D97706',
                      borderRadius: "4px",
                    }}
                  >
                    <p style={{ width: "90%" }}>The file size of the pictures is large, and it may take some time to load. Please be patient while the file is being processed.</p>
                    <CloseIcon htmlColor="#D97706" style={{
                      cursor: "pointer"
                    }}
                      onClick={this.closeWarning}
                    />
                  </Alert>
                )}

                {this.state.typeWarningFlag && (
                  <Alert
                    className="alertContainer"
                    severity="error"
                    icon={false}
                    style=
                    {{
                      marginTop: '32px',
                      marginBottom: "32px",
                      backgroundColor: "#FEE2E2",
                      color: "#DC2626",
                      borderLeft: '4px solid #DC2626',
                      borderRadius: "4px",
                    }}
                  >
                    <p style={{ width: "90%" }}>Unsupported file format. Please use a supported file format such as JPG, PNG, HEIC, MP4, MOV etc.</p>
                    <CloseIcon htmlColor="#DC2626" style={{
                      cursor: "pointer"
                    }}
                      onClick={this.closeWarning}
                    />
                  </Alert>
                )}
                {

                  this.state.uploadFiles.map((uploadFile) => {
                    return (
                      uploadFile.isError ?
                        <Box className="file-row">
                          <Box className="preview-container">
                            {uploadFile.type === "image" ? <img src={uploadFile.preview} alt="" className="file-preview" /> :
                              <video src={uploadFile.preview} autoPlay loop muted controls={false} style={{ objectFit: "cover" }} className="file-preview" />
                            }
                            <Box className="name-container">
                              <Typography className="error-file-name"> <img src={fileErrorIcon} style={{ marginRight: "5px" }} />{uploadFile.name}</Typography>
                              <Typography className="error-file-size">{uploadFile.size}</Typography>
                            </Box>
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                          <Button className="tryBtn" data-test-id="saveProfileClickBtn">
                            Try again
                          </Button>
                          <img src={deleteIcon}
                            data-test-id="err-delete-icon"
                            onClick={() => this.deleteFile(uploadFile.name)}
                            style={{
                              cursor: "pointer"
                            }} alt="" />
                          </Box>
                        </Box> :
                        <Box className="file-row" data-test-id="file-row">
                          <Box className="preview-container">
                            {uploadFile.type === "image" ? <img src={uploadFile.preview} alt="" className="file-preview" /> :
                              <video src={uploadFile.preview} autoPlay muted loop controls={false} style={{ objectFit: "cover" }} className="file-preview" />
                            }
                            <Box className="name-container">
                              <Typography className="file-name">{uploadFile.name}</Typography>
                              <Typography className="file-size">{uploadFile.size}</Typography>
                            </Box>
                          </Box>
                          <img src={deleteIcon}
                            data-test-id="delete-icon"
                            onClick={() => this.deleteFile(uploadFile.name)}
                            style={{
                              cursor: "pointer",
                              marginRight: "5px"
                            }} alt="" />
                        </Box>
                    )
                  })
                }
              </Box>
              <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '30px' }}>
                <Button className="cancelBtn" onClick={this.closeUploadModal}>
                  Cancel
                </Button>
                <Button className="saveBtn" disabled={this.state.uploadFiles?.length === 0} onClick={this.uploadFiles} data-test-id="continue-upload">
                  Continue
                </Button>
              </Box>
            </> :
              <>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} marginTop={"20px"}>
                  <Typography style={{
                    color: "#1C2110",
                    fontWeight: 700,
                    fontSize: "16px",
                    marginBottom: "15px"
                  }}> {(this.state.uploadedCount) === this.state.totalCount ? "Images uploaded succesfully!" : "Uploading images..."}</Typography>
                  <div
                    style={{
                      width: "100%",
                      height: "10px",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                      overflow: "hidden",
                      position: "relative",
                      marginBottom: "10px"
                    }}
                  >
                    <div
                      style={{
                        width: `${(this.state.uploadedCount / this.state.totalCount) * 100}%`,
                        height: "100%",
                        backgroundColor: "#A7C460",
                        transition: "width 0.5s ease-in-out"
                      }}
                    />
                  </div>
                  <p style={{ fontSize: "14px", color: "#5D6063" }}>
                    {this.state.uploadedCount}/{this.state.totalCount} photos uploaded
                  </p>
                </Box>
                <Divider style={{ marginTop: '10px', marginBottom: '25px', color: '#E7E5E4' }} />
                <Box display={"flex"} justifyContent={"flex-end"} marginTop="25px" marginBottom={"25px"} onClick={this.closeUploadModal}>
                  {this.state.uploadedCount === this.state.totalCount ? <Button className="saveBtn" disabled={this.state.uploadFiles?.length === 0} data-test-id="saveProfileClickBtn">
                    Done
                  </Button> : <Button className="cancelBtn" onClick={this.closeUploadModal}>
                    Cancel
                  </Button>}
                </Box>
              </>
            }
          </DialogProfileContent>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const DialogProfileContent = styled(Box)(() => ({
  padding: "20px",
  alignItems: "center",
  minHeight: "285px",
  "& .file-preview": {
    width: "56px",
    height: "56px",
    borderRadius: "8px",
    "@media(max-width: 600px)": {
      width: "40px",
      height: "40px",
    },
  },
  "& .upload-images-container": {
    maxHeight: "285px",
    overflowY: "auto",
    scrollbarColor: "#8CA74B transparent",
    scrollbarWidth: "thin",
    marginTop: "35px",
    "@media(max-width: 768px)": {
      maxHeight: "205px"
    },
  },
  "@media(max-width: 768px)": {
      minHeight: "205px"
  },
  "& .headerProfileBox": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  "& .MuiAlert-message": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .file-size": {
    color: "#78716C",
    "@media(max-width: 768px)": {
      fontSize: "14px",
    },
  },
  "& .file-name": {
    color: "#1C2110",
    fontSize: "16px",
    "@media(max-width: 768px)": {
      fontSize: "14px",
      maxWidth: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
  },
  "& .file-row": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px"
  },
  "& .name-container": {
    marginLeft: "15px"
  },
  "& .pictureText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color: '#000000',
    letterSpacing: '0.5%'
  },
  "& .preview-container": {
    display: "flex",
    alignItems: "center"
  },
  "& .imgInnerBox": {
    border: '2px dashed #D6D3D1',
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: "200px"
  },
  "& .error-file-name": {
    color: "#DC2626",
    display: "flex",
    alignItems: "center",
    "@media(max-width: 768px)": {
      fontSize: "14px",
      maxWidth: "80%",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
  },
  "& .error-file-size": {
    color: "#F87171",
    "@media(max-width: 768px)": {
      fontSize: "14px",
      maxWidth: "90%",
    },
  },
  "& .uploadImgText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
  },
  "& .dragImgText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#202020',
  },
  "& .browseBtn": {
    marginTop: "15px",
    '& .MuiButton-label': {
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: '#557C29',
      textTransform: 'none'
    }
  },
  "& .browseFileText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#8CA74B',
    marginLeft: '2px',
  },
  "& .yourPhotosText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: '#1C2110',
    marginTop: '20px'
  },
  "& .imgsBox": {
    display: 'flex',
    gap: '16px',
    marginTop: '10px'
  },
  "& .viewBtn": {
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "none",
    fontFamily: "Poppins",
    lineHeight: "22px",
    color: '#557C29',
    '& .MuiButton-label': {
      textTransform: 'none'
    }
  },
  "& .cancelBtn": {
    borderRadius: '8px',
    border: '1px solid #C2E176',
    backgroundColor: '#F8FFE7',
    width: '180px',
    height: '44px',
    marginRight: "10px",
    '& .MuiButton-label': {
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 700,
      color: '#8CA74B',
      fontFamily: "Poppins",
      lineHeight: "24px",
    }
  },
  "& .tryBtn": {
    width: '100px',
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    height: '32px',
    whiteSpace: "nowrap",
    "@media(max-width: 550px)": {
        marginLeft: "-15px"
      },
    '& .MuiButton-label': {
      fontWeight: 600,
      fontSize: "16px",
      fontFamily: "Poppins",
      textTransform: "none",
      color: 'white',
      lineHeight: "24px",
      "@media(max-width: 550px)": {
        fontSize: "12px",
        whiteSpace: "nowrap"
      },
    }
  },
  "& .saveBtn": {
    width: '180px',
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    height: '44px',
    '& .MuiButton-label': {
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: "Poppins",
      textTransform: "none",
      color: 'white',
      lineHeight: "24px",
    }
  },

  "& .uploadImgText, & .dragImgText, & .browseFileText, & .yourPhotosText, & .viewBtn, & .cancelBtn, & .saveBtn": {
    fontFamily: "Poppins",
    fontWeight: 700,
  }
}));
// Customizable Area End

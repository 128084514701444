import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ChangeEvent, createRef } from "react";
import { toast } from "react-hot-toast";
import { getStorageData } from "../../../framework/src/Utilities";

interface FileData {
  name: string;
  size: string;
  preview: string;
  isError: boolean;
  metaData: File;
  type: string;
}

interface UploadMediaResponse {
  id: number;
  data: string;
  file_name: string;
  status: boolean;
  celebrity_details: string;
  message: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  uploadModalOpen: boolean;
  uploadFiles: FileData[];
  sizeWarningFlag: boolean;
  typeWarningFlag: boolean;
  isUploadInProgress: boolean;
  uploadedCount: number;
  totalCount: number;
  errorCount: number;
  successFileList: Array<string>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Uploadmedia3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInputRef = createRef<HTMLInputElement>();
  uploadFileId: string = "";
  currUploadedCount: number = 0;
  currErrorCount: number = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      uploadModalOpen: false,
      uploadFiles: [],
      sizeWarningFlag: false,
      typeWarningFlag: false,
      isUploadInProgress: false,
      uploadedCount: 0,
      totalCount: 0,
      errorCount: 0,
      successFileList: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    this.handleApiResponse(apiRequestCallId, responseJson);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleApiResponse = (apiRequestCallId: string, responseJson: UploadMediaResponse & { errors: string }) => {
    if (apiRequestCallId === this.uploadFileId) {
      if (!(responseJson?.status)) {
        this.currErrorCount = this.currErrorCount + 1;
        this.currUploadedCount = this.currUploadedCount + 1;
        this.setState({
          errorCount: this.currErrorCount,
          uploadedCount: this.currUploadedCount,
        }, () => {
          if (this.currUploadedCount < this.state.uploadFiles.length) {
            this.handleFileUpload(this.state.uploadFiles[this.currUploadedCount]?.metaData)
          }
        });
      }
      else {
        this.currUploadedCount = this.currUploadedCount + 1;
        this.setState(
          (prevState) => ({
            uploadedCount: this.currUploadedCount,
            successFileList: [...prevState.successFileList, responseJson.file_name]
          }),
          () => {
            if (
              this.currUploadedCount < this.state.uploadFiles.length &&
              this.state.uploadFiles[this.currUploadedCount]?.metaData
            ) {
              this.handleFileUpload(this.state.uploadFiles[this.currUploadedCount].metaData);
            }
          }
        );

      }

      if (this.currUploadedCount === this.state.uploadFiles.length && this.state.errorCount > 0) {
        const updatedFileList = this.state.uploadFiles.map(file => ({
          ...file,
          isError: !(this.state.successFileList.includes(file.name))
        }));

        this.setState({
          isUploadInProgress: false,
          uploadFiles: updatedFileList,
        })
      }

    }
  }

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  
  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  
    if (event.dataTransfer?.files.length > 0) {
      const fileList = event.dataTransfer.files;
      const inputEvent = { target: { files: fileList } } as unknown as ChangeEvent<HTMLInputElement>;
      this.handleFileChange(inputEvent);
    }
  };

  closeUploadModal = () => {
    this.setState({
      uploadModalOpen: false,
      isUploadInProgress: false,
      uploadFiles: [],
      totalCount: 0,
      uploadedCount: 0,
      errorCount: 0
    });
    this.currUploadedCount = 0;
    this.currErrorCount = 0
  }

  formatFileSize = (sizeInBytes: number): string => {
    if (sizeInBytes < 1024) return `${sizeInBytes} B`;
    const sizeKB = sizeInBytes / 1024;
    if (sizeKB < 1024) return `${sizeKB.toFixed(2)} KB`;
    const sizeMB = sizeKB / 1024;
    if (sizeMB < 1024) return `${sizeMB.toFixed(2)} MB`;
    const sizeGB = sizeMB / 1024;
    return `${sizeGB.toFixed(2)} GB`;
  };


  deleteFile = (fileName: string): void => {
    const { uploadFiles } = this.state;
    const updatedFiles = uploadFiles.filter((file: FileData) => file.name !== fileName);

    this.setState({ uploadFiles: updatedFiles });
  };


  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const { uploadFiles } = this.state;

      if(this.state.errorCount > 0){
        this.setState({
          uploadFiles: [],
          totalCount: 0,
          uploadedCount: 0,
          errorCount: 0
        });
        this.currUploadedCount = 0;
        this.currErrorCount = 0
      }

      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp", "heic", "raw"];
      const videoExtensions = ["mp4", "mov", "avi", "mkv", "wmv", "flv", "webm", "3gp"];
      const allowedExtensions = [...imageExtensions, ...videoExtensions];

      const newFiles = Array.from(event.target.files).map(file => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        const isValidExtension = fileExtension ? allowedExtensions.includes(fileExtension) : false;
        const fileType = imageExtensions.includes(fileExtension!) ? "image" : "video";
        if (!isValidExtension){
          this.setState({
            typeWarningFlag : true
          })
        }
        return {
            name: file.name,
            size: this.formatFileSize(file.size),
            byteSize: file.size,
            preview: URL.createObjectURL(file),
            isError: !isValidExtension,
            metaData: file,
            type: fileType
        };
    });

      const filteredFiles: FileData[] = [];
      newFiles.forEach(newFile => {
        if (uploadFiles.some(existingFile => existingFile.name === newFile.name)) {
          toast.error(`"${newFile.name}" is already uploaded`);
        } else {
          if (newFile.byteSize > configJSON.warningFileSizeFlag * 1024 * 1024) {
            this.setState({
              sizeWarningFlag: true
            })
          }
          filteredFiles.push(newFile);
        }
      });

      if (filteredFiles.length > 0) {
        this.setState(prevState => ({
          uploadFiles: [...prevState.uploadFiles, ...filteredFiles],
          totalCount: [...prevState.uploadFiles, ...filteredFiles].length
        }));
      }
    }
  };

  uploadFiles = () => {
    this.setState({
      isUploadInProgress: true
    })
    this.handleFileUpload(this.state.uploadFiles[this.currUploadedCount].metaData);
  }

  handleFileUpload = async(file: File) => {

    const authToken = await getStorageData("token");
    const formData = new FormData();
    formData.append("media[files]", file as File);

    const header = { token: authToken };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.uploadFileId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadMediaEndpoint
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  handleUploadButtonClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  closeWarning = () => {
    this.setState({
      sizeWarningFlag: false
    })
  }

  closeTypeError = () => {
    this.setState({
      typeWarningFlag: false
    })
  }
  
  openUploadModal = () => {
    this.setState({
      uploadModalOpen: true
    })
  }

  // Customizable Area End
}

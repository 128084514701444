import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as React from 'react';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  resetPasswordData: {
    password: string,
    confirmPassword: string,
  };
  formError: {
    password: string,
    confirmPassword: string,
  };
  showPassword: boolean;
  showConfirmPassword: boolean;
  showModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordAccountId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      resetPasswordData: {
        password: '',
        confirmPassword: '',
      },
      formError: {
        password: '',
        confirmPassword: '',
      },
      showPassword: false,
      showConfirmPassword: false,
      showModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.resetPasswordAccountId
    ) {
      if (responseJson?.errors && responseJson?.errors?.length !== 0) {
        this.setState({
          resetPasswordData: {
            password: '',
            confirmPassword: '',
          },
        })
      }
      else {
        this.setState({ 
          showModal: true,
          resetPasswordData: {
          password: '',
          confirmPassword: '',
        },});
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start  
  handleCloseModel = () => {
    this.setState({ showModal: false });
    setTimeout(() => {
      this.props.navigation.navigate("Login");
    }, 2000);
  };

  validatePassword = [
    {
      regex: configJSON.capital,
      text: "At least one capital letter"
    },
    {
      regex: configJSON.lower,
      text: "At least one lowercase letter"
    },
    {
      regex: configJSON.digit,
      text: "At least one number"
    },
    {
      regex: configJSON.length,
      text: "Minimum character length is 8 characters"
    },
  ];

  handleClickShowPassword = (field: string) => {
    if (field === "password") {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword
      }));
    } else if (field === "confirmPassword") {
      this.setState((prevState) => ({
        showConfirmPassword: !prevState.showConfirmPassword
      }));
    }
  };

  checkValidatePassword = (password: string) => {
    let isValidPassword = true;
    this.validatePassword.forEach((item: { regex: RegExp }) => {
      if (!item.regex.test(password)) {
        isValidPassword = false;
      }
    });
    return isValidPassword;
  };

  resetPasswordSchema = Yup.object({
    password: Yup.string()
    .matches(/^\S+$/, 'Password cannot have blank spaces.')
      .required("Please enter your password"),
    confirmPassword: Yup.string()
    .matches(/^\S+$/, 'Confirm password cannot have blank spaces.')
      .required("Please enter your confirm password"),
  });

  renderIconShowPassword = (show: boolean) => {
    return show ? (
      <VisibilityOff
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    ) : (
      <Visibility
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    );
  };

  renderIconShowConfirmPassword = (showPass: boolean) => {
    return showPass ? (
      <VisibilityOff
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    ) : (
      <Visibility
        style={{
          width: "21.65px",
          height: "18.95px",
          color: "#94A3B8"
        }}
      />
    );
  };

  submitValidateAccount = async (password: string, confirmPassword: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const authToken = urlParams.get("token");
    let token = authToken || await getStorageData("token");
    const formData = new FormData();
    formData.append(password, confirmPassword);
    formData.append('password', password);
    formData.append('confirm_password', confirmPassword);

    this.setState({
      resetPasswordData: {
        password: password,
        confirmPassword: confirmPassword
      },
    })
    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/reset_password`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  backArraowClick = () => {
    this.props.navigation.navigate("Login")
  }
  // Customizable Area End
}